.header {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 0;
  width: 100%;
  height: 75px;
  background-color: $dark-theme-bg-color;
  text-align: center;
  transition: top 0.3s ease, opacity 0.3s ease;

  .logo {
    width: 75px;
    @media only screen and (max-device-width: 400px) {
      width: 60px;
    }
    transition: transform 0.5s;
    transition-timing-function: ease-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}