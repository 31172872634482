/* Story Website classes */
body {
  background-color: white;
  color: $dark-theme-font-color;
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Varela Round', sans-serif;
}
