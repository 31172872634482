.hero {
  position: relative;
  
  .hero-img-ctr {
    width: 100vw;
    height: 177vw;
    @include media-breakpoint-up(sm) {
      height: 56.25vw;
    }
    overflow: hidden;
    
    .hero-img {
      object-fit: cover;
      &.image-position-bottom {
        object-position: bottom;
      }
      &.image-position-left {
        object-position: left;
      }
      width: 100%;
      height: 100%;

      transition: transform 1s;
      transition-timing-function: ease-out;
      &:hover {
        transform: scale(1.04);
      }
    }
  }

  .hero-text-ctr {
    width: 100%;
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
}

.text-color-dark {
  color: #545454;
}

.intro {
  .hero-text-ctr {
    position: absolute;
    top: 20%;
  }
}

.feature {
  .hero-text-ctr {
    position: absolute;
    bottom: 0;
    padding-bottom: 8%;
    text-shadow: initial;

    &.text-position-tablet-bottom {
      @include media-breakpoint-up(xs) {
        padding-bottom: 2%;
      }
    }

    &.text-position-mobile-top {
      @include media-breakpoint-down(xs) {
        bottom: initial;
        padding-bottom: initial;
        top: 10%;
      }
    }
  }
}

.separator {
  .hero-text-ctr {
    height: 400px;
    padding: 130px 0;
    background-image: url("../assets/images/percale-bg.png");
  }
}

.feature {
  .hero-text-ctr {
    &.text-overlay-both, &.text-overlay-desktop-only {
      &.text-color-dark {
        background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,255));
      }
      &.text-color-light {
        background-image: linear-gradient(#55555500, #555555ff);
      }
    }
    &.text-overlay-desktop-only {
      @include media-breakpoint-down(xs) {
        &.text-color-dark {
          background-image: none;
        }
        &.text-color-light {
          background-image: none;
        }
      }
    }
  }
}
