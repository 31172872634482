.ctabutton {
  position: absolute;
  right: 10px;

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    text-decoration: none;
    transition: transform 0.5s;

    &:hover {
      transform: scale(1.05);
    }

    img {
      width: 16px;
      margin-right: 5px;
    }
  }
}