.contact {
  background-color: $dark-theme-bg-color;
  color: $dark-theme-font-color;
  font-size: 1.2rem;
  word-break: break-word;

  .whatsapp {
    color: white;
    font-weight: bolder;
  }

  .socialicons img {
    transition: transform 0.5s;

    &:hover {
      transform: scale(1.1);
    }
  }
}